<template>
  <div
    class="container mt-4">     
     <div class="row" :style="device ? 'margin-top: 0pt' : 'margin-top: 70pt'">
      <div class="col center-col">
        <div class="dropdown p-4" id="card" :style="device ? 'margin-top: 0px' : 'margin-top: 70px'">         
          <form
            class="px-4 py-3"
            @submit.prevent="signIn"
            style="margin-bottom: 37px"
          >
            <div class="mb-3">
              <i class="fas fa-user icon-name"></i>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="user.nombre"
                :placeholder="$t(`login.email`)"
              />
            </div>
            <div class="mb-3">
              <i class="fas fa-key icon-password"></i>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                id="exampleDropdownFormPassword1"
                v-model.trim="user.password"
                :placeholder="$t(`login.password`)"
              />
              <input
                v-else
                type="text"
                class="form-control"
                id="epassword"
                v-model.trim="user.password"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <button type="submit" class="btn button">
              {{ $t("actions.singin") }}
            </button>
          </form>
          <span v-if="error == 1" class="text-danger" style="font-size: 15px">{{
            $t("login.errorUsu")
          }}</span>
          <span
            v-if="error === 2"
            class="text-danger"
            style="font-size: 15px"
            >{{ $t("login.errorBlock") }}</span
          >
          <!-- <router-link class="dropdown-item label" :to="restore" style="width: auto">{{
            $t("login.forgot")
          }}</router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from 'vuex';

export default {
  name: "Login",
  data() {
    return {
      user: {
        nombre: "",
        password: "",
      },
      visPas: true,
      error: 0,
      restore: ""
    };
  },
  created() {
    this.littleDrop();
    this.changeBreadCrumb(22);
  },
  computed: {
    ...mapState(["device"]),
    block() {
      if (!this.user.email.includes("@")) {
        return true;
      }
      if (this.user.password.length > 5) {
        return false;
      }
      return true;
    },
    ...mapState(["colorCompany","servidor","logo"])
  },
  methods: {
    ...mapMutations(['littleDrop','changeBreadCrumb']),
    async signIn() {
      if (this.user.nombre === "") {
        this.error = 1;
        return;
      }
      if (this.user.password === "") {
        this.error = 1;
        return;
      }
      const superuser = {
        usu_usuario: this.user.nombre.toUpperCase(),
        usu_clave: this.user.password,
      };
      try {
        const res = await axios.post(
          `${this.servidor}api/ident_profesor.asp?ac=identificar`,
          superuser
        );
        this.result = res.data.RESULT;       

        // 1 incorrecta usuario o clave
        // 0 todo bien
        // 2 usuario bloqueado

        if (this.result === "0") {
          this.$router.push(`/profesor/inicio`);
        } else if (this.result === "1") {
          this.error = 1;
        } else {
          this.error = 2;
        }
        this.cleanForm();
      } catch (error) {
        console.error(error);
      }
      // VueCookieNext.setCookie("louscd", true, { expire: Infinity });
    },
    cleanForm() {
      this.user = {
        nombre: "",
        password: "",
      };
    }
  },
};
</script>

<style scoped>
#card {
  width: 500px;
  border-radius: 30px;
  background-color: white;
}

.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

.icon-name {
  float: left;
  top: 31px;
  position: relative;
  left: 11px;
}

.icon-password {
  float: left;
  top: 31px;
  position: relative;
  left: 11px;
}

.button {
  padding: 10px;
  width: 100%;
  border-radius: 50px;
  color: #fff;
  background-color: #e99417;
}

.label {
  font-size: 10pt;
  color: #a4a9a8;
  margin-top: 19pt;
  float: right;
}

input {
  height: 35pt;
  text-indent: 50px;
}

.center-col {
  display: flex;
  justify-content: center;
}

.img-fondo {
  margin-top: 72pt;
  width: 100%;
  height: 75%;
  position: fixed;
  margin-left: -205pt;
}

.img-logo {
  padding: 5pt;
  width: 225pt;
}
</style>